import axios, {AxiosResponse} from 'axios'
import {ID, BakhterTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Province, ProvincesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROVINCE_URL = `${API_URL}/admin/provinces`
const GET_PROVINCES_URL = `${API_URL}/admin/provinces`

const getProvinces = (query: string): Promise<ProvincesQueryResponse | BakhterTelecomResponse<Province[]>> => {
  return axios
    .get(`${GET_PROVINCES_URL}?${query}`)
    .then((d: AxiosResponse<BakhterTelecomResponse<Province[]>>) => {
      return d.data
    })
}

const getProvinceById = async (id: ID): Promise<Province> => {
  try {
    const {data} = await axios.get(`${PROVINCE_URL}/${id}`)
    return data?.data?.province
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${PROVINCE_URL}/${id}`).then((response: AxiosResponse<Province>) => response.data)
}
const createProvince = (province: Province): Promise<Province | undefined> => {
  return axios
    .post(PROVINCE_URL, province)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Province>) => response.data)
}

const updateProvince = (province: Province): Promise<Province | undefined> => {
  return axios
    .put(`${PROVINCE_URL}/${province.id}`, province)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Province>) => response.data)
}

const deleteProvince = (provinceId: ID): Promise<void> => {
  return axios.delete(`${PROVINCE_URL}/${provinceId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedProvinces = (provinceIds: Array<ID>): Promise<void> => {
  const requests = provinceIds.map((id) => axios.delete(`${PROVINCE_URL}/${id}`))
  return axios.all(requests).then((response) => {
    toast.success("Action completed...",{position:'top-right'})
  })
}

export {
  getProvinces,
  deleteProvince,
  deleteSelectedProvinces,
  getProvinceById,
  createProvince,
  updateProvince,
}
