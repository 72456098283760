import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialCountry, Country, Province, District, Currency } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CountriesListLoading } from '../components/loading/CountriesListLoading'
import { createCountry, updateCountry } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import { Language } from '../../../language/languages-list/core/_models'

type Props = {
  isCountryLoading: boolean
  country: Country
}

const editCountrySchema = Yup.object().shape({
  profile_image_url: Yup.mixed(),
})

const CountryEditModalForm: FC<Props> = ({ country, isCountryLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const CURRENCY_URL = `${API_URL}/admin/currencies`;
  const LANGUAGE_URL = `${API_URL}/admin/languages`;
  const intl = useIntl()
  // Function to handle image selection
  const [selectedImage, setSelectedImage] = useState<string | null>(null);



  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [currencies, setCurrencies] = useState<[Currency]>();
  const [languages, setLanguages] = useState<[Language]>();
  useEffect(() => {
    // Make the API request using Axios
    //get companies
    axios
      .get(CURRENCY_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setCurrencies(data.data.currencies);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    //get companies
    axios
      .get(LANGUAGE_URL)
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setLanguages(data.data.languages);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const [countryForEdit] = useState<Country>({
    ...country,
    country_name: country.country_name || initialCountry.country_name,
    country_telecom_code: country.country_telecom_code || initialCountry.country_telecom_code,
    phone_number_length: country.phone_number_length || initialCountry.phone_number_length,
    language_id: country.language_id || initialCountry.language_id,
    currency: country?.currency?.id || initialCountry?.currency?.id,

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: countryForEdit,
    validationSchema: editCountrySchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("form values", values);
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCountry(values)
        } else {
          await createCountry(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })
  // Function to handle image selection
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      formik.setFieldValue('country_flag_image', file);
    }
  };
  return (
    <>
      <form id='kt_modal_add_country_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_country_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_country_header'
          data-kt-scroll-wrappers='#kt_modal_add_country_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='d-block fw-bold fs-6 mb-5'>
              <FormattedMessage id='COUNTRY.FORM.INPUT.FLAGIMAGE' />
            </label>
            {/* end::Label */}

            {/* begin::Image input */}
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{ backgroundImage: `url('${selectedImage ? selectedImage : country.country_flag_image_url}')` }}            >
              {/* begin::Preview existing avatar */}
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{ backgroundImage: `url('${selectedImage ? selectedImage : country.country_flag_image_url}')` }}              ></div>
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CHANGEPROFILE' })}
              >
                <i className='bi bi-pencil-fill fs-7'></i>

                <input type='file'
                  name='country_flag_image' onChange={(event) => handleImageChange(event)} accept='.png, .jpg, .jpeg' />
                <input type='hidden' name='country_flag_image_remove' />
              </label>
              {/* end::Label */}

              {/* begin::Cancel */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CANCELPROFILE' })}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Cancel */}

              {/* begin::Remove */}
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                onClick={(e) => setSelectedImage('')}
                data-bs-toggle='tooltip'
                title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.REMOVEPROFILE' })}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
              {/* end::Remove */}
            </div>
            {/* end::Image input */}

            {/* begin::Hint */}
            <div className='form-text'></div>
            {/* end::Hint */}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='COUNTRY.FORM.INPUT.COUNTRYNAME' />
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'COUNTRY.FORM.PLACEHOLDER.COUNTRYNAME' })}
              {...formik.getFieldProps('country_name')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.country_name && formik.errors.country_name },
                {
                  'is-valid': formik.touched.country_name && !formik.errors.country_name,
                }
              )}
              type='text'
              name='country_name'
              autoComplete='off'
              disabled={formik.isSubmitting || isCountryLoading}
            />
            {/* end::Input */}
            {formik.touched.country_name && formik.errors.country_name && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_id}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='COUNTRY.FORM.INPUT.COUNTRYTELECOMCODE' />
            </label>
            {/* end::Label */}
              <div className="hint"> example - +93 is country code for Afghanistan</div>
            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'COUNTRY.FORM.PLACEHOLDER.COUNTRYTELECOMCODE' })}
              {...formik.getFieldProps('country_telecom_code')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.country_telecom_code && formik.errors.country_telecom_code },
                {
                  'is-valid': formik.touched.country_telecom_code && !formik.errors.country_telecom_code,
                }
              )}
              type='text'
              name='country_telecom_code'
              autoComplete='off'
              disabled={formik.isSubmitting || isCountryLoading}
            />
            {/* end::Input */}
            {formik.touched.country_telecom_code && formik.errors.country_telecom_code && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_id}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='COUNTRY.FORM.INPUT.PHONENUMBERLENGTH' />
            </label>
            {/* end::Label */}
              <div className="hint"> example - 10 is legnth for Afghanistan</div>
            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'COUNTRY.FORM.PLACEHOLDER.PHONENUMBERLENGTH' })}
              {...formik.getFieldProps('phone_number_length')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.phone_number_length && formik.errors.phone_number_length },
                {
                  'is-valid': formik.touched.phone_number_length && !formik.errors.phone_number_length,
                }
              )}
              type='text'
              name='phone_number_length'
              autoComplete='off'
              disabled={formik.isSubmitting || isCountryLoading}
            />
            {/* end::Input */}
            {formik.touched.phone_number_length && formik.errors.phone_number_length && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.language_id}</span> */}
              </div>
            )}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='COUNTRY.FORM.INPUT.CURRENCY' />
          </label>
            <select
              {...formik.getFieldProps('currency_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.currency && formik.errors.currency },
                {
                  'is-valid': formik.touched.currency && !formik.errors.currency,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCountryLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {currencies && currencies.map(currency => (
                <option value={currency.id} key={currency.id}>{currency.name}</option>
              ))}
            </select>
            {formik.touched.currency && formik.errors.currency && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.service_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}
          {/* begin::Input group */}

          <div className="fv-row mb-10"><label className="form-label required">
            <FormattedMessage id='COUNTRY.FORM.INPUT.LANGUAGE' />
          </label>
            <select
              {...formik.getFieldProps('language_id')}
              className={clsx(
                'form-select form-select-lg form-select-solid form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.language_id && formik.errors.language_id },
                {
                  'is-valid': formik.touched.language_id && !formik.errors.language_id,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isCountryLoading}

            ><option value='' defaultValue={""}>{intl.formatMessage({ id: 'FORM.GENERAL.SELECT' })}</option>
              {languages && languages.map(language => (
                <option value={language.id} key={language.id}>{language.language_name}</option>
              ))}
            </select>
            {formik.touched.language_id && formik.errors.language_id && (
              <div className='fv-plugins-message-container'>
                {/* <span role='alert'>{formik.errors.service_id}</span> */}
              </div>
            )}</div>
          {/* end::Input group */}

        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-countries-modal-action='cancel'
            disabled={formik.isSubmitting || isCountryLoading}
          >
            <FormattedMessage id='FORM.GENERAL.DISCARD' />
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-countries-modal-action='submit'
            disabled={isCountryLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isCountryLoading) && (
              <span className='indicator-progress'>
                <FormattedMessage id='FORM.GENERAL.PLEASEWAIT' />
                {' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isCountryLoading) && <CountriesListLoading />}
    </>
  )
}

export { CountryEditModalForm }
