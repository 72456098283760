import {FC, useState, createContext, useContext, useEffect} from 'react'
import {
  WithChildren,
  KhwarnetQueryRequestContextProps,
  initialQueryRequestBakhterTelecom,
  KhwarnetQueryState,
} from '../../../../../_metronic/helpers'

const QueryRequestContext = createContext<KhwarnetQueryRequestContextProps>(
  initialQueryRequestBakhterTelecom
)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<KhwarnetQueryState>(initialQueryRequestBakhterTelecom.state)

  const updateState = (updates: Partial<KhwarnetQueryState>) => {
    const updatedState = {...state, ...updates} as KhwarnetQueryState
    setState(updatedState)
  }
  useEffect(() => {
    // alert('hi')
  })
  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
