import {ID, BakhterTelecomResponse, Response} from '../../../../../_metronic/helpers'
import { Country } from '../../../country/countries-list/core/_models'
import { TelegramId } from '../Company-edit-modal/CompanyEditModalForm'
export type Company = {
  id?: any 
  company_name?: string
  company_logo?: string
  country_id?:string
  country?: Country |undefined
  telegram_chat_id?:TelegramId
  telegram_id?:string
}

export type CompaniesQueryResponse = BakhterTelecomResponse<Company[]>

export const initialCompany: Company = {
    id: '',
    company_name: "",
    company_logo: "",
    country_id:"",
    country: {
      country_name: '',
      id: '',
      language_id: 1,
    }
}
