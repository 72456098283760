import {ID, BakhterTelecomResponse, Response} from '../../../../../_metronic/helpers'
// import { District, Province } from '../../../language/languages-list/core/_models'
export type Currency = {
  id?: any
  name?: string
  code?: string
  language_id?: any
  exchange_rate?: string
  create_at?: Date
  updated_at?: Date
}
export type Language = {
  id?: any
  language_name?: string
  language_code?: any
  direction?: string
  updated_at?: Date
  created_at?: Date
}

export type Province = {
  id?: any
  province_name?: string
  language_id?: string
  currency_id?: string
}
export type District = {
  id?: any
  district_name?: string
  province_id?: string
  currency_id?: string
}
export type LanguagesQueryResponse = BakhterTelecomResponse<Language[]>

export const initialLanguage: Language = {
  id: '',
  language_name: '',
  language_code: '',
  direction:'ltr'
}
