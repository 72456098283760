import axios, {AxiosResponse} from 'axios'
import {ID, BakhterTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Service, ServicesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const SERVICE_URL = `${API_URL}/admin/services`
const GET_SERVICES_URL = `${API_URL}/admin/services`

const getServices = (query: string): Promise<ServicesQueryResponse | BakhterTelecomResponse<Service[]>> => {
  return axios
    .get(`${GET_SERVICES_URL}?${query}`)
    .then((d: AxiosResponse<BakhterTelecomResponse<Service[]>>) => {
      return d.data
    })
}

const getServiceById = async (id: ID): Promise<Service> => {
  try {
    const {data} = await axios.get(`${SERVICE_URL}/${id}`)
    return data?.data?.service
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${SERVICE_URL}/${id}`).then((response: AxiosResponse<Service>) => response.data)
}
const createService = (service: Service): Promise<Service | undefined> => {
  return axios
    .post(SERVICE_URL, service)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Service>) => response.data)
}

const updateService = (service: Service): Promise<Service | undefined> => {
  return axios
    .put(`${SERVICE_URL}/${service.id}`, service)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Service>) => response.data)
}

const deleteService = (serviceId: ID): Promise<void> => {
  return axios.delete(`${SERVICE_URL}/${serviceId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedServices = (serviceIds: Array<ID>): Promise<void> => {
  const requests = serviceIds.map((id) => axios.delete(`${SERVICE_URL}/${id}`))
  return axios.all(requests).then(() => {
    toast.success('Action completed...')
  })
}

export {
  getServices,
  deleteService,
  deleteSelectedServices,
  getServiceById,
  createService,
  updateService,
}
