import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ordersColumns} from './columns/_columns'
import {Order} from '../core/_models'
import {OrdersListLoading} from '../components/loading/OrdersListLoading'
import {OrdersListPagination} from '../components/pagination/OrdersListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'

const OrdersTable = () => {
  const orders = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => orders, [orders])
  const columns = useMemo(() => ordersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_orders'
          className='table align-middle table-row-dashed fs-8 gy-2 dataTable no-footer text-black' // Adjusted for smaller font and black color
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-black fw-bolder fs-9 text-uppercase gs-0'> {/* Smaller font size and black color */}
              {headers.map((column: ColumnInstance<Order>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-black fw-bold' {...getTableBodyProps()}> {/* Black text color */}
            {rows.length > 0 ? (
              rows.map((row: Row<Order>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <OrdersListPagination />
      {isLoading && <OrdersListLoading />}
    </KTCardBody>
  )
}

export {OrdersTable}
