import axios, {AxiosResponse} from 'axios'
import {ID, BakhterTelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Company, CompaniesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const COMPANY_URL = `${API_URL}/admin/companies`
const GET_COMPANIES_URL = `${API_URL}/admin/companies`

const getCompanies = (query: string): Promise<CompaniesQueryResponse | BakhterTelecomResponse<Company[]>> => {
  return axios
    .get(`${GET_COMPANIES_URL}?${query}`)
    .then((d: AxiosResponse<BakhterTelecomResponse<Company[]>>) => {
      return d.data
    })
}

const getCompanyById = async (id: ID): Promise<Company> => {
  try {
    const {data} = await axios.get(`${COMPANY_URL}/${id}`)
    return data?.data?.company
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${COMPANY_URL}/${id}`).then((response: AxiosResponse<Company>) => response.data)
}
const createCompany = (company: Company): Promise<Company | undefined> => {
  
  const formData = new FormData();
  Object.entries(company).forEach(([key, value]) => {
    // Handle image separately
      formData.append(key, value);
  });

  return axios
    .post(COMPANY_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
   .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Company>) => response.data)
}


const updateCompany = (company: Company): Promise<Company | undefined> => {
  const newFormData = new FormData();
  Object.entries(company).forEach(([key, value]) => {
    // Handle image separately
      newFormData.append(key, value);
  });
  // newFormData.append('_method','put');
  return axios
    .post(`${COMPANY_URL}/${company.id}`, newFormData,{
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
   .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Company>) => response.data)
}


const deleteCompany = (companyId: ID): Promise<void> => {
  return axios.delete(`${COMPANY_URL}/${companyId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedCompanies = (companyIds: Array<ID>): Promise<void> => {
  const requests = companyIds.map((id) => axios.delete(`${COMPANY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getCompanies,
  deleteCompany,
  deleteSelectedCompanies,
  getCompanyById,
  createCompany,
  updateCompany,
}
