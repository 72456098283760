import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Reseller, ResellersQueryResponse } from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const RESELLER_URL = `${API_URL}/admin/resellers`
const GET_RESELLERS_URL = `${API_URL}/admin/resellers`

const getResellers = (query: string): Promise<ResellersQueryResponse> => {
  return axios
    .get(`${GET_RESELLERS_URL}?${query}`)
    .then((d: AxiosResponse<ResellersQueryResponse>) => {
      return d.data
    })
}

const getResellerById = async (id: ID): Promise<Reseller> => {
  try {
    const { data } = await axios.get(`${RESELLER_URL}/${id}`)
    return data?.data?.reseller
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
}

const createReseller = (reseller: Reseller): Promise<Reseller | undefined> => {
  const formData = new FormData()
  Object.entries(reseller).forEach(([key, value]) => {
    // Handle image separately
    formData.append(key, value)
  })
  return axios
    .post(RESELLER_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
    .then((response: any) => {
      if (response.data.success == true) {
        toast.success(response.data.message, { position: 'top-right' })
      } else {
        toast.error(response?.data?.message, { position: 'top-right' })
      }
      return response.data
    })
    .then((response: Response<Reseller>) => response.data)
}

const updateReseller = (reseller: Reseller): Promise<Reseller | undefined> => {
  const formData = new FormData()
  Object.entries(reseller).forEach(([key, value]) => {
    // Handle image separately
    formData.append(key, value)
  })
  // formData.append('_method','put');
  return axios
    .post(`${RESELLER_URL}/${reseller.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
      },
    })
    .then((response: any) => {
      if (response.data.success == true) {
        toast.success(response.data.message, { position: 'top-right' })
      } else {
        toast.error(response?.data?.message, { position: 'top-right' })
      }
      return response.data
    })
    .then((response: Response<Reseller>) => response.data)
}

const deleteReseller = (resellerId: ID): Promise<void> => {
  return axios.delete(`${RESELLER_URL}/${resellerId}`).then(() => {})
}

const changeResellerStatus = (resellerId: ID): Promise<void> => {
  return axios.get(`${RESELLER_URL}/change-reseller-status/${resellerId}`).then(() => {})
}

const deleteSelectedResellers = (resellerIds: Array<ID>): Promise<void> => {
  const requests = resellerIds.map((id) => axios.delete(`${RESELLER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

// New function to get sub-resellers
const getSubResellersByResellerId = (resellerId: ID): Promise<Reseller[]> => {
  return axios
    .get("https://amutele.bakhtertelecom.com/api/admin/resellers")
    .then((response: AxiosResponse<{ data: Reseller[] }>) => response.data.data)
}


export {
  getResellers,
  deleteReseller,
  changeResellerStatus,
  deleteSelectedResellers,
  getResellerById,
  createReseller,
  updateReseller,
  getSubResellersByResellerId,  // Export the new function
}
