// @ts-nocheck
import {Column} from 'react-table'
import {BundleInfoCell} from './BundleInfoCell'
import {BundleLastLoginCell} from './BundleLastLoginCell'
import {BundleTwoStepsCell} from './BundleTwoStepsCell'
import {BundleActionsCell} from './BundleActionsCell'
import {BundleSelectionCell} from './BundleSelectionCell'
import {BundleCustomHeader} from './BundleCustomHeader'
import {BundleSelectionHeader} from './BundleSelectionHeader'
import {Bundle} from '../../core/_models'
import { FormattedMessage } from 'react-intl'
import { CompanyInfoCell } from '../../../../company/companies-list/table/columns/CompanyInfoCell'

import dayjs from 'dayjs';


const bundlesColumns: ReadonlyArray<Column<Bundle>> = [
  {
    Header: (props) => <BundleSelectionHeader tableProps={props} />,
    id: 'selection',
    className: 'min-w-40px', // Added width for the selection column
    Cell: ({...props}) => <BundleSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.BUNDLENAME' />}
        className='' // Decreased width
      />
    ),
    id: 'bundle_title',
    accessor: 'bundle_title',
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.BUNDLEDESCRIPTION' />}
        className='' // Decreased width
      />
    ),
    id: 'bundle_description',
    accessor: 'bundle_description',
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.VALIDITYTYPE' />}
        className='' // Decreased width
      />
    ),
    id: 'validity_type',
    accessor: 'validity_type',
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.ADMINBUYINGPRICE' />}
        className='' // Decreased width
      />
    ),
    id: 'admin_buying_price',
    accessor: 'admin_buying_price',
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.BUYINGPRICE' />}
        className='' // Decreased width
      />
    ),
    id: 'buying_price',
    accessor: 'buying_price',
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.SELLINGPRICE' />}
        className='' // Decreased width
      />
    ),
    id: 'selling_price',
    accessor: 'selling_price',
    Cell: ({value}) => `${value}`,
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.CURRENCYNAME' />}
        className='' // Further decreased width
      />
    ),
    accessor: 'currency.name',
    Cell: ({value}) => value,
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.COMPANYNAME' />}
        className='' // Adjusted width
      />
    ),
    accessor: 'service.company.company_name',
    id: 'company_logo',
    Cell: ({...props}) => <BundleInfoCell bundle={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='BUNDLE.TABLE.COLUMN.SERVICECATEGORY' />}
        className='' // Further decreased width
      />
    ),
    accessor: 'service.service_category.category_name',
    Cell: ({value}) => value,
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TABLE.GENERAL.CREATEDAT' />}
        className='' // Adjusted width
      />
    ),
    id: 'created_at',
    accessor: 'created_at',
    Cell: ({value}) => (
      <span>
        {dayjs(value).format('MMMM DD, YYYY HH:mm')}
      </span>
    ),
  },
  {
    Header: (props) => (
      <BundleCustomHeader
        tableProps={props}
        title={<FormattedMessage id='TABLE.GENERAL.ACTION' />}
        className='text-end ' // Decreased width
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <BundleActionsCell id={props.data[props.row.index].id} />,
  },
];

export {bundlesColumns};



