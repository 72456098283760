import React from 'react'
import { ProfileDetails } from '../../../modules/accounts/components/settings/cards/ProfileDetails'
import { ConnectedAccounts } from '../../../modules/accounts/components/settings/cards/ConnectedAccounts'
import { EmailPreferences } from '../../../modules/accounts/components/settings/cards/EmailPreferences'
import { DeactivateAccount } from '../../../modules/accounts/components/settings/cards/DeactivateAccount'
import { Notifications } from '../../../modules/accounts/components/settings/cards/Notifications'
import { Reseller } from '../resellers-list/core/_models'
import { ResellerProfileDetails } from './ResellerProfileDetails'
import { ResellerBalanceTransaction } from './RsellerBalanceTransaction'
import { ResellerChangePassword } from './RsellerChangePassword'
import { ResellerChangePin } from './RsellerChangePin'

type Props = {
  isResellerLoading: boolean
  reseller: Reseller | any
}
export const ResellerSettings: React.FC<Props> = ({ reseller, isResellerLoading }) => {
  return (
    <>
    <ResellerBalanceTransaction reseller={reseller} isResellerLoading={isResellerLoading} />
      {/* <ResellerProfileDetails isResellerLoading={isResellerLoading} reseller = {reseller} /> */}
      <ResellerChangePassword isResellerLoading={isResellerLoading} reseller = {reseller} />
      <ResellerChangePin isResellerLoading={isResellerLoading} reseller = {reseller} />
      
      {/* <ConnectedAccounts /> */}
      {/* <EmailPreferences /> */}
      {/* <Notifications /> */}
      {/* <DeactivateAccount /> */}
    </>
  )
}
