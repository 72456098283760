import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { initialReseller, Reseller } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ResellersListLoading } from '../components/loading/ResellersListLoading'
import { createReseller, updateReseller } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Country, District, Province } from '../../../country/countries-list/core/_models'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import { Currency } from '../../../currency/currencies-list/core/_models'

type Props = {
  isResellerLoading: boolean
  reseller: Reseller
}

const editResellerSchema = Yup.object().shape({
  reseller_name: Yup.string().required('Reseller name is required'),
  contact_name: Yup.string().required('Contact name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  currency_preference_id: Yup.number().required('Currency preference is required'),
  country_id: Yup.number().required('Country is required'),
  province_id: Yup.number(),
  districts_id: Yup.number(),
});

const ResellerEditModalForm: FC<Props> = ({ reseller, isResellerLoading }) => {
  const API_URL = process.env.REACT_APP_THEME_API_URL;
  const COUNTRY_URL = `${API_URL}/admin/countries`;
  const PROVINCE_URL = `${API_URL}/admin/provinces`;
  const DISTRICTS_URL = `${API_URL}/admin/districts`;
  const CURRENCIES_URL = `${API_URL}/admin/currencies`;
  const intl = useIntl();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { itemIdForUpdate, setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [countries, setCountries] = useState<[Country]>();
  const [provinces, setProvinces] = useState<[Province]>();
  const [districts, setDistricts] = useState<[District]>();
  const [currencies, setCurrencies] = useState<[Currency]>();

  useEffect(() => {
    axios.get(COUNTRY_URL).then((response) => {
      const data = response.data;
      if (data.success) setCountries(data.data.countries);
    }).catch((error) => console.error(error));

    axios.get(PROVINCE_URL).then((response) => {
      const data = response.data;
      if (data.success) setProvinces(data.data.provinces);
    }).catch((error) => console.error(error));

    axios.get(DISTRICTS_URL).then((response) => {
      const data = response.data;
      if (data.success) setDistricts(data.data.districts);
    }).catch((error) => console.error(error));

    axios.get(CURRENCIES_URL).then((response) => {
      const data = response.data;
      if (data.success) setCurrencies(data.data.currencies);
    }).catch((error) => console.error(error));
  }, []);

  const [resellerForEdit] = useState<Reseller>({
    ...reseller,
    profile_image_url: reseller.profile_image_url || initialReseller.profile_image_url,
    reseller_name: reseller.reseller_name || initialReseller.reseller_name,
    contact_name: reseller.contact_name || initialReseller.contact_name,
    email: reseller.email || initialReseller.email,
    phone: reseller.phone || initialReseller.phone,
    balance: reseller.balance || initialReseller.balance,
    country_id: reseller.country_id || initialReseller.country_id,
    province_id: reseller.province_id || initialReseller.province_id,
    districts_id: reseller.districts_id || initialReseller.districts_id,
    currency_preference_id: reseller.currency_preference_id || initialReseller.currency_preference_id,
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) refetch();
    setItemIdForUpdate(undefined);
  };

  const formik = useFormik({
    initialValues: resellerForEdit,
    validationSchema: editResellerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (isNotEmpty(values.id)) {
          await updateReseller(values);
        } else {
          await createReseller(values);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    },
  });

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      formik.setFieldValue('profile_image_url', file);
    }
  };

  return (
    <>
      <form id='kt_modal_add_reseller_form' encType="multipart/form-data" className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_reseller_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_reseller_header' data-kt-scroll-wrappers='#kt_modal_add_reseller_scroll' data-kt-scroll-offset='300px'>
          
          {/* Image Upload */}
          <div className='row'>
            <div className='col-md-6'>
              <div className='fv-row mb-1'>
                <label className='d-block fw-bold fs-6 mb-5'>
                  <FormattedMessage id='RESELLER.FORM.INPUT.PROFILE' />
                </label>
                <div className='image-input image-input-outline' data-kt-image-input='true' style={{ backgroundImage: `url('${selectedImage ? selectedImage : reseller.profile_image_url}')` }}>
                  <div className='image-input-wrapper w-125px h-125px' style={{ backgroundImage: `url('${selectedImage ? selectedImage : reseller.profile_image_url}')` }}></div>
                  <label className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action='change' data-bs-toggle='tooltip' title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.CHANGEPROFILE' })}>
                    <i className='bi bi-pencil-fill fs-7'></i>
                    <input type='file' name='profile_image_url' onChange={handleImageChange} accept='.png, .jpg, .jpeg' />
                    <input type='hidden' name='profile_image_url_remove' />
                  </label>
                  <span className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action='remove' onClick={() => setSelectedImage('')} data-bs-toggle='tooltip' title={intl.formatMessage({ id: 'RESELLER.FORM.INPUT.REMOVEPROFILE' })}>
                    <i className='bi bi-x fs-2'></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Reseller Name & Contact Name */}
          <div className='row'>
            <div className='col-md-6'>
              {/* Reseller Name */}
              <div className='fv-row mb-1'>
                <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='RESELLER.FORM.INPUT.RESELLERNAME' /></label>
                <input placeholder={intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.RESELLERNAME' })} {...formik.getFieldProps('reseller_name')} className={clsx('form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.reseller_name && formik.errors.reseller_name }, { 'is-valid': formik.touched.reseller_name && !formik.errors.reseller_name })} type='text' name='reseller_name' autoComplete='off' disabled={formik.isSubmitting || isResellerLoading} />
                {formik.touched.reseller_name && formik.errors.reseller_name && <div className='fv-plugins-message-container'><span role='alert'>{formik.errors.reseller_name}</span></div>}
              </div>
            </div>
            <div className='col-md-6'>
              {/* Contact Name */}
              <div className='fv-row mb-1'>
                <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='RESELLER.FORM.INPUT.CONTACTNAME' /></label>
                <input placeholder={intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.CONTACTNAME' })} {...formik.getFieldProps('contact_name')} className={clsx('form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.contact_name && formik.errors.contact_name }, { 'is-valid': formik.touched.contact_name && !formik.errors.contact_name })} type='text' name='contact_name' autoComplete='off' disabled={formik.isSubmitting || isResellerLoading} />
                {formik.touched.contact_name && formik.errors.contact_name && <div className='fv-plugins-message-container'><span role='alert'>{formik.errors.contact_name}</span></div>}
              </div>
            </div>
          </div>

          {/* Email & Phone */}
          <div className='row'>
            <div className='col-md-6'>
              <div className='fv-row mb-1'>
                <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='RESELLER.FORM.INPUT.EMAIL' /></label>
                <input placeholder={intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.EMAIL' })} {...formik.getFieldProps('email')} className={clsx('form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.email && formik.errors.email }, { 'is-valid': formik.touched.email && !formik.errors.email })} type='email' name='email' autoComplete='off' disabled={formik.isSubmitting || isResellerLoading} />
                {formik.touched.email && formik.errors.email && <div className='fv-plugins-message-container'><span role='alert'>{formik.errors.email}</span></div>}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='fv-row mb-1'>
                <label className='required fw-bold fs-6 mb-2'><FormattedMessage id='RESELLER.FORM.INPUT.PHONE' /></label>
                <input placeholder={`${intl.formatMessage({ id: 'RESELLER.FORM.PLACEHOLDER.PHONE' })} +93700xxxxxxx`} {...formik.getFieldProps('phone')} className={clsx('form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.phone && formik.errors.phone }, { 'is-valid': formik.touched.phone && !formik.errors.phone })} type='text' name='phone' autoComplete='off' disabled={formik.isSubmitting || isResellerLoading} />
                {formik.touched.phone && formik.errors.phone && <div className='fv-plugins-message-container'><span role='alert'>{formik.errors.phone}</span></div>}
              </div>
            </div>
          </div>

          {/* Other Fields */}
          <div className='row'>
            <div className='col-md-6'>
              {/* Currency Preference */}
              <div className="fv-row mb-5">
                <label className="form-label required"><FormattedMessage id='RESELLER.FORM.INPUT.CURRENCYPREFERENCE' /></label>
                <select defaultValue={reseller.currency_preference_id} {...formik.getFieldProps('currency_preference_id')} className={clsx('form-select form-select-lg form-select-solid form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.currency_preference_id && formik.errors.currency_preference_id }, { 'is-valid': formik.touched.currency_preference_id && !formik.errors.currency_preference_id })} autoComplete='off' disabled={formik.isSubmitting || isResellerLoading}>
                  <option value='' disabled>-- Select --</option>
                  {currencies && currencies.map(currency => (
                    <option key={currency.id} disabled={(itemIdForUpdate !=null && currency.id == currency.id)} selected={(itemIdForUpdate !=null && currency.id == currency.id)} value={currency.id}>{currency.name}-{currency.code}</option>
                  ))}
                </select>
                {formik.touched.currency_preference_id && formik.errors.currency_preference_id && <div className='fv-plugins-message-container'><span role='alert'>{String(formik.errors.currency_preference_id)}</span></div>}
              </div>
            </div>

            <div className='col-md-6'>
              {/* Country */}
              <div className="fv-row mb-5">
                <label className="form-label required"><FormattedMessage id='RESELLER.FORM.INPUT.COUNTRY' /></label>
                <select {...formik.getFieldProps('country_id')} className={clsx('form-select form-select-lg form-select-solid form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.country_id && formik.errors.country_id }, { 'is-valid': formik.touched.country_id && !formik.errors.country_id })} autoComplete='off' disabled={formik.isSubmitting || isResellerLoading}>
                  <option value='' >-- Select --</option>
                  {countries && countries.map(country => (
                    <option key={country.id} value={country.id}>{country.country_name}</option>
                  ))}
                </select>
                {formik.touched.country_id && formik.errors.country_id && <div className='fv-plugins-message-container'><span role='alert'>{String(formik.errors.country_id)}</span></div>}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              {/* Province */}
              <div className="fv-row mb-5">
                <label className="form-label required"><FormattedMessage id='RESELLER.FORM.INPUT.PROVINCE' /></label>
                <select placeholder='Province' {...formik.getFieldProps('province_id')} className={clsx('form-select form-select-lg form-select-solid form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.province_id && formik.errors.province_id }, { 'is-valid': formik.touched.province_id && !formik.errors.province_id })} autoComplete='off' disabled={formik.isSubmitting || isResellerLoading}>
                  <option value='' selected>-- Select --</option>
                  {provinces && provinces.map(province => (
                    <option key={province.id} value={province.id}>{province.province_name}</option>
                  ))}
                </select>
                {formik.touched.province_id && formik.errors.province_id && <div className='fv-plugins-message-container'><span role='alert'>{String(formik.errors.province_id)}</span></div>}
              </div>
            </div>

            <div className='col-md-6'>
              {/* District */}
              <div className="fv-row mb-5">
                <label className="form-label required"><FormattedMessage id='RESELLER.FORM.INPUT.DISTRICT' /></label>
                <select {...formik.getFieldProps('districts_id')} className={clsx('form-select form-select-lg form-select-solid form-control form-control-solid mb-1 mb-lg-0', { 'is-invalid': formik.touched.districts_id && formik.errors.districts_id }, { 'is-valid': formik.touched.districts_id && !formik.errors.districts_id })} autoComplete='off' disabled={formik.isSubmitting || isResellerLoading}>
                  <option value='' selected>-- Select --</option>
                  {districts && districts.map(district => (
                    <option key={district.id} value={district.id}>{district.district_name}</option>
                  ))}
                </select>
                {formik.touched.districts_id && formik.errors.districts_id && <div className='fv-plugins-message-container'><span role='alert'>{String(formik.errors.districts_id)}</span></div>}
              </div>
            </div>
          </div>
        </div>

        <div className='text-center pt-5'>
          <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-resellers-modal-action='cancel' disabled={formik.isSubmitting || isResellerLoading}>Discard</button>
          <button type='submit' className='btn btn-primary' data-kt-resellers-modal-action='submit' disabled={isResellerLoading || formik.isSubmitting || !formik.isValid || !formik.touched}>
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isResellerLoading) && (<span className='indicator-progress'>Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>)}
          </button>
        </div>
        {(formik.isSubmitting || isResellerLoading) && <ResellersListLoading />}
      </form>
    </>
  )
}

export { ResellerEditModalForm }
