/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Service } from '../../core/_models'

type Props = {
  service: Service
}

const ServiceInfoCell: FC<Props> = ({ service }) =>{
  return(
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          {service.company?.company_logo ? (
            <div className='symbol-label'>
              <img src={`${service?.company?.company_logo}`} alt={service?.service_name} className='w-100' />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-3',
                `bg-light-${service.company?.company_name}`,
                `text-${service.company?.company_name}`
              )}
            >
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {service.company?.company_name}
        </a>
        <span>{service.company?.country?.country_name}</span>
      </div>
    </div>
  )
}

export { ServiceInfoCell }
