/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES, openWhatsApp } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteOrder, confirmOrder, rejectOrder, setOrderUnderProcess } from '../../core/_requests' // Add setOrderUnderProcess import
import { Link, Navigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Order } from '../../core/_models'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'

type Props = {
  order: Order
}

const OrderActionsCell: FC<Props> = ({ order }) => {
  const id = order.id;
  const status = order.status;
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteOrder(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    }
  })

  const confirmDelete = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    })

    if (result.isConfirmed) {
      deleteItem.mutate()
    }
  }

  const confirmResellerOrder = useMutation(() => confirmOrder(id), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    }
  })

  const confirmingOrder = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to confirm this order!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, confirm it!',
    })

    if (result.isConfirmed) {
      confirmResellerOrder.mutate()
    }
  }

  const rejectResellerOrder = useMutation((rejectReason: string) => rejectOrder(id, rejectReason), {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    }
  })

  const confirmReject = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to reject this order!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, reject it!',
    })

    if (result.isConfirmed) {
      const { value: rejectReason } = await Swal.fire({
        title: 'Enter reject reason:',
        input: 'text',
        inputPlaceholder: 'Type reject reason',
        showCancelButton: true,
      })

      if (rejectReason) {
        rejectResellerOrder.mutate(rejectReason)
      } else {
        console.log('User clicked Cancel for reject reason')
      }
    }
  }

  const setOrderToUnderProcess = useMutation(() => setOrderUnderProcess(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
    onError: () => {
      toast.error('Sorry The Action Failed...', { position: 'bottom-right' })
    }
  })

  const handleUnderProcess = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to set this order as Under Process!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, set it!',
    })

    if (result.isConfirmed) {
      setOrderToUnderProcess.mutate()
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <FormattedMessage id='TABLE.GENERAL.ACTION' />
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {status !== 1 && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={async () => await confirmingOrder()}>
              <FormattedMessage id='TABLE.GENERAL.CONFIRM' />
            </a>
          </div>
        )}
        {(status === 0 || status === 3) && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={async () => await confirmReject()}>
              <FormattedMessage id='TABLE.GENERAL.REJECT' />
            </a>
          </div>
        )}
        {status !== 1 && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-orders-table-filter='delete_row'
              onClick={async () => await confirmDelete()}
            >
              <FormattedMessage id='TABLE.GENERAL.DELETE' />
            </a>
          </div>
        )}
        {(status !== 3 && status != 1) && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={async () => await handleUnderProcess()}
            >
              <FormattedMessage id='TABLE.GENERAL.UNDERPROCESS' />
            </a>
          </div>
        )}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { OrderActionsCell }
